require("./bootstrap");

window.Vue = require("vue");

import sponsoredImg from "./components/sponsored-img";
import alert from "./components/alert";

Vue.component("sponsored-img", sponsoredImg);
Vue.component("alert", alert);

if (document.getElementById("app")) {
    const app = new Vue({
        el: "#app"
    });
}
