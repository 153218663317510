<template>
    <div
        :class="[
            'fixed-bottom',
            'container',
            show ? 'z-index-top' : 'z-index-bottom'
        ]"
    >
        <div
            :class="[
                'alert',
                'alert-' + this.color,
                'alert-dismissible',
                'fade',
                show ? 'show' : ''
            ]"
            role="alert"
        >
            {{ content }}
            <button
                type="button"
                class="close"
                @click="close()"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</template>

<script>
    import {setTimeout, clearTimeout} from "timers";

    export default {
        props: {
            content: {
                type: String,
                required: true
            },
            color: {
                type: String,
                default: "danger"
            },
            timeout: {
                type: Number,
                default: 0
            },
            opened: {
                Boolean,
                default: false
            }
        },
        data() {
            return {
                curTimeout: undefined,
                show: false
            };
        },
        mounted() {
            if (this.opened)
                this.open();
        },
        methods: {
            close() {
                this.show = false;
            },
            open() {
                this.show = true;
                if (this.curTimeout == undefined) {
                    this.curTimeout = setTimeout(this.close, this.timeout);
                } else {
                    clearTimeout(this.curTimeout);
                    this.curTimeout = setTimeout(this.close, this.timeout);
                }
            }
        }
    };
</script>


<style scoped>
</style>

