var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'fixed-bottom',
        'container',
        _vm.show ? 'z-index-top' : 'z-index-bottom'
    ]},[_c('div',{class:[
            'alert',
            'alert-' + this.color,
            'alert-dismissible',
            'fade',
            _vm.show ? 'show' : ''
        ],attrs:{"role":"alert"}},[_vm._v("\n        "+_vm._s(_vm.content)+"\n        "),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.close()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }