<template>
    <div id="sponsorImgWrapper" class="position-relative" :class="wrapperclass">
        <img id="contentImg" :class="imgclass" :src="src" :alt="alt" />
        <img
            v-if="curSponsor"
            id="curSponsorImg"
            ref="curSponsorImg"
            :class="[
                'position-absolute',
                logocorner,
                'uhe-sponsored-logo-wrapper',
                maxwidth
            ]"
            class="uhe-sponsored-logo-img"
            :src="'/storage/' + curSponsor.image.thumbPath"
        />
        <img
            v-if="nextSponsor"
            id="nextSponsorImg"
            ref="nextSponsorImg"
            :class="[
                'position-absolute',
                logocorner,
                'uhe-sponsored-logo-wrapper',
                maxwidth
            ]"
            class="uhe-sponsored-logo-nextimg uhe-o-0"
            :src="'/storage/' + nextSponsor.image.thumbPath"
        />
    </div>
</template>

<script>
import { setInterval, setTimeout } from "timers";
export default {
    props: {
        sponsors: {
            type: Array,
            required: false
        },
        logocorner: {
            type: String,
            required: false,
            default: "top-right"
        },
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            required: false
        },
        imgclass: {
            type: String,
            required: false,
            default: ""
        },
        wrapperclass: {
            type: String,
            required: false,
            default: ""
        },
        maxwidth: {
            type: String,
            required: false,
            default: "default"
        }
    },
    data() {
        return {
            curSponsor: undefined,
            curSponsorArrInd: undefined,
            nextSponsor: {
                image: {
                    thumbPath: ""
                }
            },
            nextSponsorArrInd: undefined,
            weightedSponsorIds: [],
            fadeTime: 100
        };
    },
    mounted() {
        if (this.sponsors[0] != undefined) {
            this.curSponsor = this.sponsors[0];
            this.curSponsorArrInd = 0;
        }
        this.sponsors.forEach((sponsor, ind) => {
            for (let i = 0; i < 6 - sponsor.priority; i++) {
                this.weightedSponsorIds.push(ind);
            }
        });

        if (this.sponsors.length > 1) setInterval(this.changeSponsor, 5000);
    },
    methods: {
        changeSponsor() {
            let curSponsorId = this.curSponsorArrInd;
            let newSponsorId = 0;
            let cur = this.$refs.curSponsorImg;
            let next = this.$refs.nextSponsorImg;

            do {
                let arrInd = Math.floor(
                    Math.random() * this.weightedSponsorIds.length
                );
                newSponsorId = this.weightedSponsorIds[arrInd];
            } while (newSponsorId == curSponsorId);

            cur.classList.toggle("fade");

            this.nextSponsor = this.sponsors[newSponsorId];
            this.nextSponsorArrInd = newSponsorId;

            next.classList.toggle("fade");

            setTimeout(this.removeFadeClasses, 1000);
        },
        removeFadeClasses() {
            let cur = this.$refs.curSponsorImg;
            let next = this.$refs.nextSponsorImg;

            this.curSponsor = this.nextSponsor;
            this.curSponsorArrInd = this.nextSponsorArrInd;

            cur.classList.toggle("fade");
            next.classList.toggle("fade");
        }
    }
};
</script>

<style scoped>
.sm {
    max-width: 20%;
}

.default {
    max-width: 33%;
}

@media (min-width: 992px) {
    .default {
        max-width: 33%;
    }
}

.uhe-sponsored-logo-img {
    max-height: 33%;
    z-index: 2;
    opacity: 0.8;
}

.uhe-sponsored-logo-nextimg {
    max-height: 33%;
    z-index: 2;
    opacity: 0;
}

.uhe-sponsored-logo-nextimg.fade {
    opacity: 0.8;
    transition: opacity 1s;
}

.uhe-sponsored-logo-img.fade {
    opacity: 0;
    transition: opacity 1s;
}

.bottom-right {
    bottom: 0;
    right: 0;
}

.bottom-left {
    bottom: 0;
    left: 0;
}

.top-right {
    top: 0;
    right: 0;
}

.top-left {
    top: 0;
    left: 0;
}
</style>
