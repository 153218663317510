var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative",class:_vm.wrapperclass,attrs:{"id":"sponsorImgWrapper"}},[_c('img',{class:_vm.imgclass,attrs:{"id":"contentImg","src":_vm.src,"alt":_vm.alt}}),_vm._v(" "),(_vm.curSponsor)?_c('img',{ref:"curSponsorImg",staticClass:"uhe-sponsored-logo-img",class:[
            'position-absolute',
            _vm.logocorner,
            'uhe-sponsored-logo-wrapper',
            _vm.maxwidth
        ],attrs:{"id":"curSponsorImg","src":'/storage/' + _vm.curSponsor.image.thumbPath}}):_vm._e(),_vm._v(" "),(_vm.nextSponsor)?_c('img',{ref:"nextSponsorImg",staticClass:"uhe-sponsored-logo-nextimg uhe-o-0",class:[
            'position-absolute',
            _vm.logocorner,
            'uhe-sponsored-logo-wrapper',
            _vm.maxwidth
        ],attrs:{"id":"nextSponsorImg","src":'/storage/' + _vm.nextSponsor.image.thumbPath}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }